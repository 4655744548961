import React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "@/components/Layout";
import Plate from "@/components/Plate";
import Hero from "@/components/Hero";

import { Text, Grid, Flex, Box, Container } from "theme-ui";

export interface HomeProps {
  data?: any;
}

const Home: React.FC<HomeProps> = ({ data }) => {
  const postEdges = (data && data.caseStudies && data.caseStudies.edges) || [];

  return (
    <Layout>
      <Plate variant="styles.contentFrame">
        <Container>
          <Plate variant="styles.contentFrame2">
            <Text as="h1" variant="pageTitle">
              About CCS
            </Text>
            <Text as="p" variant="sectionBody">
              CCS is a speciality healthcare facility that performs
              multi-disciplinary surgeries in a safe and conducive environment.
              We offer a holistic treatment from pre-surgery preparation to
              post- operative care. Introducing an unprecedented treatment
              environment, administrative work is separated from the surgery
              paraphernalia allowing surgeons at the centre to solely focus on
              the surgery with the aid of trained staff working round the clock.
              Our efforts are directed at making our treatment process an
              economical and time-saving alternative for the patients. We make
              surgery both a patient and surgeon friendly experience to
              prioritize restoration of your health above everything else.
            </Text>

            <h3>What we stand for</h3>
            <Text as="p" variant="sectionBody">
              We believe that the convenience and comfort of both patients and
              surgeons are integral to the quality healthcare we strive to
              provide. Patient experience is the foundation of our approach to
              healthcare. We envision patient-centred healthcare wherein lack of
              availability of operation theatres does not impede the delivery of
              high quality and safe surgical care. A well-thought-out futuristic
              vision coupled with an empathetic approach can revolutionise the
              current healthcare scenario and that is what we aim for.
            </Text>
          </Plate>

          <h2>Overview</h2>
          <Grid gap={2} columns={[2, null, 2]}>
            <Box sx={{ maxWidth: "60ch", mr: 5, mb: 4 }}>
              <Text as="h1" variant="sectionTitle">
                Next Generation Idea
              </Text>
              <Text as="p" variant="sectionBody">
                A positive trend in surgical care, our speciality referral
                surgery center provides a high-quality patient-surgeon
                experience while significantly reducing costs. With all the
                facilities lined up conveniently, we are able to provide great
                accessibility and utmost professionalism to both the surgeons
                and patients.
              </Text>
            </Box>
            <Box sx={{ maxWidth: "60ch" }}>
              <Text as="h1" variant="sectionTitle">
                Growing Community of Doctors
              </Text>
              <Text as="p" variant="sectionBody">
                We have faculty surgeons specialized in different branches of
                medicine. Physicians can refer patients that require surgical
                attention to our facility.
              </Text>
            </Box>
            <Box sx={{ maxWidth: "60ch" }}>
              <Text as="h1" variant="sectionTitle">
                First of its Kind
              </Text>
              <Text as="p" variant="sectionBody">
                Evolving with the dynamic healthcare landscape, we are the first
                healthcare facility in the state to open it's doors exclusively
                for referral surgeries.
              </Text>
            </Box>

            <Box sx={{ maxWidth: "60ch" }}>
              <Text as="h1" variant="sectionTitle">
                Location
              </Text>
              <Text as="p" variant="sectionBody">
                Our facility is located in the heart of Calicut making it easily
                accessible to patients and physicians. Being situated at the
                premises of Metromed Cardiac Center, we can handle complicated
                emergency cases too.
              </Text>
            </Box>

            <Box sx={{ maxWidth: "60ch" }}>
              <Text as="h1" variant="sectionTitle">
                Staff
              </Text>
              <Text as="p" variant="sectionBody">
                Our surgical teams include qualified and dedicated nurses,
                anaesthetists, and surgical technologists. Staff specialised in
                various surgical fields are available at the surgeon's disposal.
              </Text>
            </Box>
          </Grid>
        </Container>
      </Plate>
    </Layout>
  );
};

export default Home;
